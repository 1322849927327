import request from '@/api/request.js';
let data = {
    navList: [],
    init() {
        let that = this;
        return new Promise(function (resolve, reject) {
            
            request({
                url: '/login/getUser',
                method: 'get'
            }).then(res => {
                sessionStorage.setItem('openId', res.data.user.openid);
                sessionStorage.setItem('nickName', res.data.user.nickname);
                sessionStorage.setItem('imgUrl', res.data.user.headimgurl);
                sessionStorage.setItem('userjurid', res.data.user.userjurid);
                sessionStorage.setItem('usermarid', res.data.user.usermarid);
                sessionStorage.setItem('username', res.data.user.username);
                sessionStorage.setItem('userid', res.data.user.userid);
                sessionStorage.setItem('useraccount', res.data.user.useraccount);
                return request({
                    url: '/nav/getNavAll',
                    method: 'get',
                    params: {
                        userMarId: res.data.user.usermarid,
                        userJurId: res.data.user.userjurid
                    }
                })
            }).then(res => {
                that.navList = res.data.navList;
                sessionStorage.setItem('navList', JSON.stringify(res.data.navList));
                resolve(res + '/' + that.navList.length);
            }).catch(err => {
                reject();
                console.log(err);
            });
        })
    }
}

export default data;