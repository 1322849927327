<template>
    <div>
        <indexDetail ref="indexDetail"/>
    </div>
</template>

<script>
import indexDetail from '@/components/indexDetail.vue';
export default {
  name: 'indexView',
  components: {
    indexDetail
  },
  data() {
    return {
      id: '',
      fullscreenLoading:false
    };
  },
  created() {
    this.id = Number.parseInt(this.$route.params.id);
  },
  methods: {
    
  }
};
</script>

<style scoped>
</style>
