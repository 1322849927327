<template>
  <div v-if="ifshow">
    <van-card
      v-for="(item, index) in tableData"
      :key="index"
      :desc="item.customerInfo"
    >
      <template #thumb>
        <div style="width: 88px; height: 88px">
          <van-image :src="item.printscreen" @click="showImgView(item)" />
        </div>
      </template>
      <template #title>
        <van-icon name="manager-o" /><span style="color: blue">运营:</span
        ><span>{{ item.operations }}</span
        >&nbsp;&nbsp;&nbsp; 
        <span>{{ item.navName }}</span
        >&nbsp;&nbsp;&nbsp;<span>{{ item.contact }}</span>
      </template>
      <template #tags>
        <van-icon name="contact-o" /><span
          style="color: blue"
          >客服:</span
        ><span v-if="item.customerService">{{ item.customerService }}</span
        ><span v-else>未指定</span> &nbsp;&nbsp;&nbsp;
        <van-tag v-if="item.browseTime == null" plain type="danger" >未浏览线索</van-tag>
        <van-tag v-else plain type="danger" >{{ computeDate(item.dispathTime,item.browseTime) }}浏览线索</van-tag>&nbsp;&nbsp;
        <van-tag v-if="item.updateTime == null" plain type="danger" >未更新线索</van-tag>
        <van-tag v-else plain type="danger" >{{ computeDate(item.dispathTime,item.updateTime) }}更新线索</van-tag>
      </template>
      <template #footer>
        <van-button v-if="jurid == 4 || jurid == 6" type="primary" block size="small" @click="handlebind(index, item.id)"
          >获取信息</van-button
        >
        <van-button v-if="jurid == 1 || jurid == 2 || jurid == 3" style="margin-top:3px;" block type="info" size="small" @click="distribute(index, item)"
          >分发</van-button
        >
      </template>
    </van-card>
    <van-dialog
      v-model="showDisDialog"
      title="分发线索给客服"
      show-cancel-button
    >
      <van-field
        readonly
        clickable
        name="picker"
        :value="fform.username"
        label="名称"
        placeholder="点击选择客服"
        @click="showUserPicker = true"
      />
    </van-dialog>
    <van-popup v-model="showUserPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="navUsers"
        value-key="username"
        @confirm="onConfirmU"
        @cancel="showUserPicker = false"
      />
    </van-popup>
  </div>
  <h1 v-else>欢迎使用，暂时没有线索</h1>
</template>

<script>
import request from '@/api/request.js';
import {ImagePreview} from 'vant';
import moment from 'moment';
export default {
  data() {
    return {
      tableData: [],
      navUsers: [],
      ifshow: false,
      showDisDialog: false,
      showUserPicker: false,
      jurid:'',
      fform: {
        userId: '',
        username: '',
        id: '',
        index: '',
      },
      form: {
        navList: '',
        operationsId: '',
        operations: '',
        customerServiceId: 0, //默认查询所有待获取的线索
        customerService: '',
        page: 1,
        pageSize: 50,
      },
    };
  },
  created() {
    this.form.navList = sessionStorage.getItem('usermarid');
    this.jurid = sessionStorage.getItem('userjurid');
    this.loadData();
  },
  methods: {
    computeDate(starTime, endTime) {
      let diffTime =
        moment(endTime, 'YYYY-MM-DD HH:mm:ss') -
        moment(starTime, 'YYYY-MM-DD HH:mm:ss');
        let text = '';
      if(diffTime<0){

          diffTime = moment(starTime, 'YYYY-MM-DD HH:mm:ss')-moment(endTime, 'YYYY-MM-DD HH:mm:ss');
      }
      let hours = Math.floor(diffTime / (3600 * 1000));
      if (hours > 0) text += hours + '小时 ';
      let leave = diffTime % (3600 * 1000);
      let minutes = Math.floor(leave / (60 * 1000));
      if (minutes > 0) text += minutes + '分钟后';
      return text;
    },
    showImgView(item) {
      let imgs = [];
      if(item.printscreen != '' && item.printscreen != null) imgs.push(item.printscreen);
      JSON.parse(item.printscreenText).forEach((i) => {
        imgs.push(i);
      });
      if(imgs.length == 0){
        this.$Notify({ type: 'warning', message: '当前线索没有图片可以预览' });
          return;
      }
      ImagePreview({
        images: imgs,
        closeable: true,
      });
    },
    onConfirmU(select) {
      this.$store.commit('showOverlay');
      this.fform.userId = select.userid;
      this.fform.username = select.username;
      request({
        url: '/customerIndex/bindCustomerIndex',
        method: 'get',
        params: {
          id: this.fform.id,
          userid: this.fform.userId,
          username: this.fform.username,
        },
      })
        .then((res) => {
          if (res.code == 200) {
            this.tableData.splice(this.fform.index, 1);
          }
          this.$store.commit('hideOverlay');
          this.showDisDialog = false;
          this.showUserPicker = false;
          this.$Notify({ type: 'success', message: res.message });
        })
        .catch((err) => {
          this.$store.commit('hideOverlay');
          this.$Notify({ type: 'warning', message: err });
        });
    },
    distribute(index, item) {
      this.showDisDialog = true;
      this.fform.index = index;
      this.fform.id = item.id;
      this.loadNavUser(item.navId);
    },
    handlebind(index, id) {
      this.$store.commit('showOverlay');
      request({
        url: '/customerIndex/bindCustomerIndex',
        method: 'get',
        params: {
          id: id,
        },
      })
        .then((res) => {
          this.$store.commit('hideOverlay');
          if (res.code == 200) {
            this.tableData.splice(index, 1);
          }
          this.$Notify({ type: 'success', message: res.message });
        })
        .catch((err) => {
          this.$store.commit('hideOverlay');
          this.$Notify({ type: 'warning', message: err });
        });
    },
    loadNavUser(stanavid) {
      request({
        url: '/login/getNavUserAll',
        method: 'get',
        params: {
          stanavid: stanavid,
          stagroid: 0,
        },
      })
        .then((res) => {
          this.navUsers = res.data.userList;
        })
        .catch((err) => {});
    },
    loadData() {
      request({
        url: '/customerIndex/getNoDispatchList',
        method: 'get',
        params: this.form,
      })
        .then((res) => {
          this.tableData = res.records;
          this.totalCount = res.total;
          this.loading = false;
          if (this.tableData.length > 0) {
            this.ifshow = true;
          } else {
            this.ifshow = false;
          }
        })
        .catch((error) => {
          this.$Notify({ type: 'warning', message: error });
        });
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-form-item {
  margin-bottom: 0px;
}
</style>
