<template>
  <div class="g-tabbar">
    <router-link
      class="g-tabbar-item"
      to="/welcome"
    >
      <i class="iconfont home-fill home"></i>
      <span>首页</span>
    </router-link>
    <router-link
      class="g-tabbar-item"
      to="/listIndex"
    >
      <i class="iconfont table-fill table"></i>
      <span>线索池</span>
    </router-link>
    <router-link
      class="g-tabbar-item"
      to="/uploadIndex"
    >
      <i class="iconfont order-upload-fill order-upload"></i>
      <span>上传线索</span>
    </router-link>
    <router-link
      class="g-tabbar-item"
      to="/person/myIndex"
    >
      <i class="iconfont favorites-star-fill favorites-star"></i>
      <span>我的线索</span>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'Tabbar',
  data () {
    return {

    };
  },
  methods: {

  }
};
</script>

<style lang="less" scoped>
.g-tabbar {
  display: flex;
  width: 100%;
  height: 50px;
  background: #fff;

  &-item {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #333;
    font-size: 12px;

    .iconfont {
      // margin-bottom:4px;
      font-size: 18px;
      height: 30px;
      width: 30px;
      background-size: 30px 30px;
    }
  }
}
.router-link-active {
  color: red;
  font-weight: bold;
}

.home {
  background-image: url("@/assets/home.png");
}
.router-link-active .home-fill {
  background-image: url("@/assets/home-fill.png");
}
.table {
  background-image: url("@/assets/table.png");
}
.router-link-active .table-fill {
  background-image: url("@/assets/table-fill.png");
}
.order-upload {
  background-image: url("@/assets/order-upload.png");
}
.router-link-active .order-upload-fill {
  background-image: url("@/assets/order-upload-fill.png");
}
.favorites-star {
  background-image: url("@/assets/favorites-star.png");
}
.router-link-active .favorites-star-fill {
  background-image: url("@/assets/favorites-star-fill.png");
}
</style>