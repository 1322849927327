import axios from 'axios'
import router from '../router'
import cookies from "vue-cookies";
//import { getCookie, delCookie } from '@/api/utils.js'
const request = axios.create({
    baseURL: 'https://mp.xycm99.cn/api',
    //baseURL: 'http://syxt002.tyqiyou.cn:7046',
    timeout: 50000
})

// request 拦截器
// 可以自请求发送前对请求做一些处理
// 比如统一加token，对请求参数统一加密
request.interceptors.request.use(config => {
    if (!config.headers['Content-Type']) config.headers['Content-Type'] = 'application/json;charset=utf-8';
    config.headers['Authorization'] = cookies.get('token');
    //console.log('token/'+getCookie('token'));
    return config
}, error => {
    return Promise.reject(error)
});

// response 拦截器
// 可以在接口响应后统一处理结果
request.interceptors.response.use(
    response => {
        let res = response.data;
        if (res.code == 401) {
            if (cookies.get('token') != null) {
                //delCookie();
                cookies.remove('token');
                router.push("/");
            }
            return Promise.reject(res.message);
        }
        // 如果是返回的文件
        if (response.config.responseType === 'blob') {
            return res
        }
        // 兼容服务端返回的字符串数据
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res
        }
        return res;
    },
    error => {
        console.log('err' + error) // for debug
        return Promise.reject(error)
    }
)

export default request