<template>
  <div class="box">
    <van-dropdown-menu>
      <van-dropdown-item v-model="currentNav" :options="navList" @change="navMenuChange" />
    </van-dropdown-menu>
    <van-form>
      <van-field v-model="currentTime" is-link readonly name="picker" required label="选择年份" placeholder="选择年份" @click="showTimePicker = true" />
    </van-form>
    <van-popup v-model="showTimePicker" position="bottom">
      <van-picker :default-index="1" show-toolbar :columns="timeList" value-key="text" @confirm="onTConfirm" @cancel="showTimePicker = false" />
    </van-popup>
    <van-row type="flex" justify="center" align="center" gutter="20" v-for="(item, index) in monthRateList" :key="index" class="d">
      <van-col span="5">{{ item.date }}</van-col>
      <van-col span="11"><van-progress :percentage="item.r" stroke-width="8" /></van-col>
      <van-col span="8"><van-button type="primary" size="small" @click="showDayPopup(item.date)">查看日转化</van-button></van-col>
    </van-row>
    <van-popup v-model="showDayRate" position="bottom" :style="{ height: '70%' }">
      <el-calendar id="workSchedule" v-model="date">
        <template slot="dateCell" slot-scope="{ date, data }">
          <span>{{ date.getDate() }}</span>

          <van-progress class="date-tag" v-if="viewDate[data.day]" :percentage="viewDate[data.day].r" stroke-width="8" />
        </template>
      </el-calendar>
      <div class="day_title">
        <div>
          <span>总线索：</span><span style="color:green;font-weight: bold;">{{ titleItem.rn+titleItem.cn }}</span>
        </div>
        <div>
          <span>成单：</span><span style="color:green;font-weight: bold;">{{ titleItem.rn }}</span>
        </div>
        <div>
          <span>转化率：</span><span style="color:green;font-weight: bold;">{{ titleItem.r }}%</span>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import request from "@/api/request.js";
import moment from "moment";
export default {
  name: "conversionRate",
  data() {
    return {
      currentNav: "",
      currentTime: "",
      currentIndex: "",
      currentMonth: "",
      showTimePicker: false,
      showDayRate: false,
      navList: [],
      monthRateList: [],
      viewDate: {},
      titleItem: {cn:0,rn:0,r:0},
      date: new Date(),
      timeList: [
        { id: "1", text: "2023" },
        { id: "2", text: "2024" },
        { id: "3", text: "2025" },
        { id: "4", text: "2026" },
        { id: "5", text: "2027" },
      ],
    };
  },
  watch: {
    date: {
      handler(newTime, oldTime) {
        if (newTime == undefined) return;
        if (oldTime == undefined) {
          return;
        }

        const newSpan = newTime.getTime();
        const oldSpan = oldTime.getTime();
        if (newSpan !== oldSpan) {
          this.starDate = moment(newTime).startOf("months").format("YYYY-MM-DD");
          let monthstr = moment(newTime).startOf("months").format("YYYY-MM");
          if (this.currentMonth != monthstr) {
            this.currentMonth = monthstr;
            this.getStatisticsDayRate(monthstr, sessionStorage.getItem("userid"), this.currentNav);
          } else {
            let currentDate = moment(newTime).format("YYYY-MM-DD");
            this.titleItem = this.viewDate[currentDate];
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    JSON.parse(sessionStorage.getItem("navList")).forEach((item, index) => {
      this.navList.push({ value: item.navid, text: item.navname });
    });
    let year = moment().format("YYYY");
    this.timeList.forEach((item, index) => {
      if (item.text == year) {
        this.currentTime = item.text;
        this.currentIndex = index;
      }
    });
    this.currentNav = this.navList[0].value;
    this.getStatisticsMonthRate(this.currentTime, sessionStorage.getItem("userid"), this.currentNav);
  },
  methods: {
    showDayPopup(date) {
      this.date = moment(date, "YYYY-MM").toDate();
      //this.currentMonth = date;
      this.showDayRate = true;
      //this.getStatisticsDayRate(date, 768, this.currentNav);
    },
    navMenuChange(value) {
      this.getStatisticsMonthRate(this.currentTime, sessionStorage.getItem("userid"), this.currentNav);
    },
    onTConfirm(index) {
      this.currentTime = index.text;
      this.getStatisticsMonthRate(this.currentTime, sessionStorage.getItem("userid"), this.currentNav);
      this.showTimePicker = false;
    },
    getStatisticsDayRate(monthstr, userId, navId) {
      request({
        url: "/customerIndex/getStatisticsDayRate",
        method: "get",
        params: {
          monthstr,
          userId,
          navId,
        },
      })
        .then((res) => {
          if (res.code == 200) {
            this.viewDate = res.data.list;
            this.titleItem = this.viewDate[monthstr+'-01'];
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getStatisticsMonthRate(yearstr, userId, navId) {
      this.$store.commit('showLoading');
      request({
        url: "/customerIndex/getStatisticsMonthRate",
        method: "get",
        params: {
          yearstr,
          userId,
          navId,
        },
      })
        .then((res) => {
          this.$store.commit('hideLoading');
          if (res.code == 200) {
            this.monthRateList = res.data.list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.d {
  margin-bottom: 0.5rem;
}
/deep/ .el-calendar-table .el-calendar-day {
  box-sizing: border-box;
  //padding: 10px;
  position: relative;
  height: auto;
  display: flex;
  flex-direction: column;
}
/deep/ .date-tag .van-progress__pivot {
  border-radius: 0px;
}
.date-tag {
  margin-top: 0.5rem;
}
.day_title {
  display: flex;
  align-content: center;
  justify-content: center;
}
.day_title div {
  margin-right: 2rem;
}
</style>
