const BASEURL = "https://mp.xycm99.cn/api"
//const BASEURL = "http://localhost:8080/api"
//const STATICURL = "https://www.static.woaijx.cn"
const URL = {
  getBaseUrl: BASEURL,
  //判断是否是微信浏览器的函数
  isWeiXin:function() {
    //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
    var ua = window.navigator.userAgent.toLowerCase();
    //console.log(JSON.stringify(ua));
    //通过正则表达式匹配ua中是否含有MicroMessenger字符串
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      return true;
    } else {
      return false;
    }
  }
}
export default URL
